import { combineReducers } from 'redux'
import { AppState } from './app/types'
import { appReducer } from './app/reducer'
import { sessionReducer } from './session/reducer'
import { SessionState } from './session/types'

export interface ApplicationState {
    app: AppState
    session: SessionState
}

export const createRootReducer = (appState: AppState) =>
    combineReducers<ApplicationState>({
        app: (state, action) => appReducer(state || appState, action),
        session: sessionReducer,
    })
