import { ActionType, getType } from 'typesafe-actions'
import actions from '../actions'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import moment from 'moment'
import { fetchEstablishment } from 'core/ressources/establishment/queries'
import { ApplicationState } from 'core/store/reducers'
import { EstablishmentModel } from 'core/models/establishmentModel'

function* sessionSagas() {
    yield takeLatest(getType(actions.initSession), init)
    yield put(actions.initSession(false))
}

function* init(action: ActionType<typeof actions.initSession>) {
    if (!action.payload) {
        if (typeof window !== 'undefined') {
            const establishment: EstablishmentModel = yield select(
                ({ app }: ApplicationState) => app.establishment,
            )
            if (establishment.id !== -1) {
                const lastFetchKey = 'establishment/:id/last_fetch'.replace(
                    ':id',
                    establishment.id.toString(),
                )
                const establishmentKey = 'establishment/:id'.replace(':id', establishment.id.toString())
                // TODO: check rehydrate
                // const lastFetch: string | null = yield window.localStorage.getItem(lastFetchKey)
                // const savedEstablishment: string | null = yield window.localStorage.getItem(establishmentKey)

                const date = moment()
                // const less5minutes = moment().add(-5, 'minutes')
                // if (
                //     savedEstablishment === null ||
                //     lastFetch === null ||
                //     moment(lastFetch).isBefore(less5minutes)
                // ) {
                const updatedEstablishment: EstablishmentModel = yield call(
                    fetchEstablishment,
                    establishment.id,
                )
                yield put(actions.setEstablishment(updatedEstablishment))
                window.localStorage.setItem(lastFetchKey, date.toISOString())
                window.localStorage.setItem(establishmentKey, JSON.stringify(updatedEstablishment))
                // } else {
                //     try {
                //         const recoveredEstablishment: EstablishmentModel = JSON.parse(savedEstablishment)
                //         yield put(actions.setEstablishment(recoveredEstablishment))
                //     } catch (error) {
                //         console.error(error)
                //     }
                // }
            }
        }

        yield put(actions.initSession(true))
    }
}

export default sessionSagas
