import * as React from 'react'
import { ComponentType } from 'react'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import { createRootReducer } from 'core/store/reducers'
import { createMiddleware } from 'core/store/middleware'
import mainSaga from 'core/store/sagas'
import createSagaMiddleware from 'redux-saga'
import { AppState } from 'core/store/app/types'
import { EstablishmentModel } from 'core/models/establishmentModel'
import { ReviewsModel } from '../../core/models/reviewsModel'

interface OutputProps {}

interface InputProps {
    pageContext: {
        establishment: EstablishmentModel
        reviews: ReviewsModel
    }
}

const withStoreProvider = (ComposedComponent: ComponentType<OutputProps>) => {
    const WithStoreProvider: React.FC<InputProps> = ({ ...props }) => {
        const { pageContext } = props
        const appState: AppState = {
            init: false,
            establishment: pageContext.establishment ?? {
                id: -1,
            },
            reviews: pageContext.reviews ?? undefined,
        }
        const rootReducer = createRootReducer(appState)
        const sagaMiddleware = createSagaMiddleware()
        const store = createStore(rootReducer, createMiddleware(sagaMiddleware))
        sagaMiddleware.run(mainSaga)
        return (
            <Provider store={store}>
                <ComposedComponent {...props} />
            </Provider>
        )
    }
    return WithStoreProvider
}

export default withStoreProvider
