import { EstablishmentModel } from 'core/models/establishmentModel'
import { ReviewsModel } from '../../models/reviewsModel'

export enum AppActionTypes {
    INIT = '@@app/INIT',
    SET_ESTABLISHMENT = '@@app/SET_ESTABLISHMENT',
}

export interface AppState {
    init: boolean
    establishment: EstablishmentModel
    reviews?: ReviewsModel
}
