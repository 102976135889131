import React, { ComponentType } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const withLocalizationProvider = (ComposedComponent: ComponentType) => {
    const WithLocalizationProvider: React.FC = ({ ...props }) => {
        return (
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <ComposedComponent {...props} />
            </LocalizationProvider>
        )
    }
    return WithLocalizationProvider
}

export default withLocalizationProvider
