import { EstablishmentModel } from 'core/models/establishmentModel'
import { client } from '../client'

export const fetchEstablishment = (id: number): Promise<EstablishmentModel> => {
    return client
        .get('/establishment/:id/public'.replace(':id', id.toString()))
        .then((response) => response.data)
}

export const fetchEstablishmentByUri = (uri: string): Promise<EstablishmentModel> => {
    return client.get('/establishment/uri/:uri'.replace(':uri', uri)).then((response) => response.data)
}

export const fetchEstablishments = (): Promise<Array<EstablishmentModel>> => {
    return client.get('/establishment/public').then((response) => response.data)
}
