import { Reducer } from 'redux'
import { SessionState } from './types'
import { getType } from 'typesafe-actions'
import actions from '../actions'

export const initialState: SessionState = {
    init: false,
}

const reducer: Reducer<SessionState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.initSession): {
            const init = action.payload
            return {
                ...state,
                init,
            }
        }
        default:
            return state
    }
}

export { reducer as sessionReducer }
