import appSagas from './app/sagas'
import { all, call } from 'redux-saga/effects'
import sessionSagas from './session/sagas'

function* mainSaga() {
    try {
        yield all([call(appSagas), call(sessionSagas)])
    } catch (error) {
        console.error(error)
    }
}

export default mainSaga
