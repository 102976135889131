import * as React from 'react'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/lazy'

const withSwiper = (ComposedComponent: React.ComponentType) => {
    const WithSwiper: React.FC = (props) => <ComposedComponent {...props} />
    return WithSwiper
}

export default withSwiper
