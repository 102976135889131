import { applyMiddleware, compose, Middleware } from 'redux'
import loggerMiddleware from 'redux-logger'

export const createMiddleware = (sagaMiddleware: Middleware) => {
    let middlewares: Array<Middleware> = []
    middlewares = [...middlewares, sagaMiddleware]
    if (process.env.GATSBY_REDUX_LOGGER === 'on') {
        middlewares = [...middlewares, loggerMiddleware]
    }
    return compose(applyMiddleware(...middlewares))
}
