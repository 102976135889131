import { ActionType, getType } from 'typesafe-actions'
import actions from '../actions'
import { put, takeLatest } from 'redux-saga/effects'

function* appSagas() {
    yield takeLatest(getType(actions.initSession), init)
}

function* init(action: ActionType<typeof actions.initSession>) {
    if (!action.payload) {
        yield put(actions.init(true))
    }
}

export default appSagas
