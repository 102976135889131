import { NoSsr } from '@mui/material'
import * as React from 'react'
import { compose } from 'redux'
import 'typeface-open-sans'
import 'typeface-roboto'
import '@fontsource/inclusive-sans'
import '@fontsource/coiny'
import '@fontsource/noto-serif'
import '@fontsource/josefin-sans'
import '@fontsource/oregano'
import '@fontsource/sumana'
import '@fontsource/sigmar'
import '@fontsource/stick-no-bills'
import '@fontsource/belanosima'
import '@fontsource/museomoderno'
import './src/assets/dunbar-tall.css'
import Container from './src/containers/container'
import withLocalizationProvider from './src/containers/hoc/withLocalizationProvider'
import withMoment from './src/containers/hoc/withMoment'
import withStoreProvider from './src/containers/hoc/withStoreProvider'
import withSwiper from './src/containers/hoc/withSwiper'

const Root = compose(withStoreProvider, withMoment, withSwiper, withLocalizationProvider)(Container)

export const wrapPageElement = ({ element, props }) => {
    return <Root pageContext={props.pageContext}>{element}</Root>
}

export const wrapRootElement = ({ element }) => {
    return <NoSsr>{element}</NoSsr>
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
    const { hash } = location
    if (!hash) {
        window.scrollTo(0, 0)
        return false
    }
    return true
}
