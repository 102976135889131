import { Reducer } from 'redux'
import { AppState } from './types'
import { getType } from 'typesafe-actions'
import actions from '../actions'

export const initialState: AppState = {
    init: false,
    // @ts-ignore
    establishment: {
        id: -1,
    },
}

const reducer: Reducer<AppState> = (state = initialState, action) => {
    switch (action.type) {
        case getType(actions.init): {
            const init = action.payload
            return {
                ...state,
                init,
            }
        }
        case getType(actions.setEstablishment): {
            const establishment = action.payload
            return {
                ...state,
                establishment,
            }
        }
        default:
            return state
    }
}

export { reducer as appReducer }
